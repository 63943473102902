import React,{ useState } from "react";
import PropTypes from "prop-types";
import moment from"moment";
import { Button } from "shards-react";
import axios from 'axios';
import ConfigConstants from "../../config/Config";
import Cookies from 'js-cookie';

const ChannelReminder = (props) => {

  const [remaningTime, setRemaningTime] = useState('');

  const skipReminder = (reminderId) => {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/reminder/skip?reminderId=' + reminderId + '&userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => {
      if(!res.data.success) {
        alert(res.data.message);
      }
    }) 
    .catch(error => { 
      if (error.response) {
          alert('Error', error.response.data);
      } else if (error.request) {
          alert('Error', error.request.responseJSON);
      } else {
          alert('Error', error.message);
      }
  })
  }

  function timer(endTime) {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;
  
    let endDate = new Date(endTime);
    endDate.setDate(endDate.getDate());
    
    let end = endDate.getTime();
    let x = setInterval(function () {
        let now = new Date().getTime(),
          timeLeft = end - now;
  
        let days = Math.floor(timeLeft / (day)),
          hours = Math.floor((timeLeft % (day)) / (hour)),
          minutes = Math.floor((timeLeft % (hour)) / (minute)),
          seconds = Math.floor((timeLeft % (minute)) / second);
  
        setRemaningTime((days !== 0 ? (days + ' day' + (days !== 1 ? 's ' : ' ')) : '') + 
            (hours < 10 ? '0' : '') + hours + ':' + 
            (minutes < 10 ? '0' : '') + minutes + ':' + 
            (seconds < 10 ? '0' : '') + seconds);

        if (timeLeft < 0) {
          setRemaningTime("Processing ...")
          clearInterval(x);
        }
      }, 1000)                    // time refresh in ms
  }

  React.useEffect(() => {
    timer(props.channelReminder['triggerTime']);
  }, []);

  return(
    <tr className="channel-row">
    <td className="channel-row-empty"></td>
    <td>{props.index}</td>
    <td>{props.channelReminder['type']}</td>
    <td>{props.channelReminder['remainingRetryCount']}</td>
    <td>{props.channelReminder['retryTime']}</td>
    <td>{props.channelReminder['waitMinute']}</td>
    <td>{(props.channelReminder['processed'] || props.channelReminder['skipped'] || props.channelReminder['cancelled']) ? '-' : (remaningTime + ' -> (' + moment(props.channelReminder['triggerTime']).format('DD/MM/YYYY HH:mm') + ')')}</td>
    {props.channelReminder['skipped'] ? <td>Skipped</td> : 
        (props.channelReminder['processed'] ? <td>Completed</td> : 
            (props.channelReminder['next'] && !props.channelReminder['cancelled'] ? 
                <td><Button style={{backgroundColor: "green"}} onClick={ () => skipReminder(props.channelReminder['id']) }>Skip</Button></td> : 
                <td></td>))}
  </tr>
  );
};

ChannelReminder.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ChannelReminder.defaultProps = {
  title: "Channel Reminder"
};

export default ChannelReminder;
