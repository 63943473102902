import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Login from "./views/Login";
import { Navigate } from "react-router-dom";
import UserDetail from "./views/UserDetail";
import CreateReminder from "./views/CreateReminder";
import { DefaultLayout } from "./layouts";
import Register from "./views/Register";
import ReminderDashboard from "./views/ReminderDashboard";
import Logout from "./views/Logout";
import TokenInfo from "./views/TokenInfo";
import ContactUs from "./views/ContactUs";

export default () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reminders-dashboard" element={<DefaultLayout><ReminderDashboard /></DefaultLayout>} />
      <Route path="/create-reminder" element={<DefaultLayout><CreateReminder /></DefaultLayout>} />
      <Route path="/user-detail" element={<DefaultLayout><UserDetail /></DefaultLayout>} />
      <Route path="/token-info" element={<DefaultLayout><TokenInfo /></DefaultLayout>} />
      <Route path="/contact-us" element={<DefaultLayout><ContactUs /></DefaultLayout>} />
    </Routes>
  </Router>
  // <Router basename={process.env.REACT_APP_BASENAME || ""}>
  //   <Routes>
  //     {routes.map((route, index) => {
  //       return (
  //         <Route
  //           key={index}
  //           path={route.path}
  //           exact={route.exact}
  //           component={withTracker(props => {
  //             return (
  //               <route.layout {...props}>
  //                 <route.component {...props} />
  //               </route.layout>
  //             );
  //           })}
  //         />
  //       );
  //     })}
  //     <Route path="/login" element={<Login />} />
  //    <Route path="/" element={<Navigate replace to="/login" />} />
  //   </Routes>
  // </Router>
);
