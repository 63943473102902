import React,{ useState }  from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ConfigConstants from "../config/Config";
import Cookies from 'js-cookie';

import "../assets/register.css"

const Register = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if(Cookies.get("username"))
            navigate("/user-detail");
     }, [])

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [email, setEmail] = useState("");

    const doRegister = () => {

        if(password === passwordAgain) {
            axios.post(ConfigConstants.backendHost + '/user/register', {
                "username": username,
                "password": password,
                "email": email
            })
            .then(res => {
                if(res.data.success) {
                    alert('User Created');
                    navigate("/login");
                } else {
                    alert(res.data.message);
                }
                
            }) 
            .catch(error => { 
                if (error.response) {
                    alert('Error: ' + error.response.data);
                } else if (error.request) {
                    alert('Error: ' + error.request.responseJSON);
                } else {
                    alert('Error: ' + error.message);
                }
            })
        } else
            alert('Attention', "Passwords are mismatched");

    }

    return(
        <div class="login-container">
        <div class="screen">
            <div class="screen__content">
                <div class="login">
                    <div class="login__field">
                        <i class="login__icon fas fa-user"></i>
                        <input type="text" class="login__input" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                    </div>
                    <div class="login__field">
                        <i class="login__icon fas fa-user"></i>
                        <input type="text" class="login__input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                    </div>
                    <div class="login__field">
                        <i class="login__icon fas fa-lock"></i>
                        <input type="password" class="login__input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    </div>
                    <div class="login__field">
                        <i class="login__icon fas fa-lock"></i>
                        <input type="password" class="login__input" value={passwordAgain} onChange={(e) => setPasswordAgain(e.target.value)} placeholder="Password Again" />
                    </div>
                    <button class="button login__submit" onClick={doRegister}>
                        <span class="button__text">Register</span>
                        <i class="button__icon fas fa-chevron-right"></i>
                    </button>				
                </div>
            </div>
            <div class="screen__background">
                <span class="screen__background__shape screen__background__shape4"></span>
                <span class="screen__background__shape screen__background__shape3"></span>		
                <span class="screen__background__shape screen__background__shape2"></span>
                <span class="screen__background__shape screen__background__shape1"></span>
            </div>		
        </div>
    </div>
    )
}

export default Register;