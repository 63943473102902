import React,{ useState } from "react";
import PropTypes from "prop-types";
import { Button } from "shards-react";
import moment from"moment";
import axios from 'axios';
import ConfigConstants from "../../config/Config";
import ChannelHeaders from "./ChannelHeaders";
import ChannelReminder from "./ChannelReminder";
import Cookies from 'js-cookie';

const ReminderList = (props) => {

  const [showChannelRules, setShowChannelRules] = useState(false);
  const [channelRuleButtonName, setChannelRuleButtonName] = useState("Open Rules");

  const changeChannelRuleVisibility = () => {
    setShowChannelRules(!showChannelRules);
    setChannelRuleButtonName(channelRuleButtonName === 'Open Rules' ? 'Close Rules' : 'Open Rules');
  }

  const cancelReminder = (groupId) => {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/reminder/cancel?groupId=' + groupId + '&userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => {
      if(!res.data.success) {
        alert(res.data.message);
      }
    }) 
    .catch(error => { 
      if (error.response) {
          alert('Error', error.response.data);
      } else if (error.request) {
          alert('Error', error.request.responseJSON);
      } else {
          alert('Error', error.message);
      }
  })
  }

  return (
    props.reminderList.map((reminder, remIndex) => (
      (
        reminder.rowType === 1 &&
        <tr>
          <td>{props.mapIndex}</td>
          <td>{reminder['name']}</td>
          <td>{moment(reminder['createdDate']).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{reminder['cost']}</td>
          <td><Button onClick={ changeChannelRuleVisibility }>{channelRuleButtonName}</Button></td>
          {
            props.active && !reminder['existProcessed'] ? <td><Button onClick={ () => cancelReminder(reminder['groupId']) } style={{backgroundColor: "orange"}}>Cancel Reminder</Button></td> : 
            <td></td>
          }
          <td></td>
          <td></td>
        </tr>
      )
      ||
      (
        reminder.rowType === 2 && showChannelRules &&
        <ChannelHeaders />
      )
      ||
      (
        reminder.rowType === 3 && showChannelRules &&
        <ChannelReminder index={(remIndex-1)} channelReminder={reminder}/>
      )
    ))
  );
};

ReminderList.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ReminderList.defaultProps = {
  title: "Reminder List"
};

export default ReminderList;
