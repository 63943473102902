import React,{ useState } from "react";
import { Container, Row, Col } from "shards-react";
import axios from 'axios';
import Cookies from 'js-cookie';
import ConfigConstants from "../config/Config";

import PageTitle from "../components/common/PageTitle";

const TokenInfo = () => {

  const [tokenCostMap, setTokenCostMap] = useState({
    Email: {}
  });

  const getTokenCosts = () =>  {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/reminder/getTokenCosts', { headers: headers })
      .then(res => {
        const response = res.data;

        if(!response.success) {
          alert(response.message);
        } else {
          setTokenCostMap(response.data.channelTokenCostMap);
        }
      }) 
      .catch(error => { 
        if (error.response) {
            alert('Error', error.response.data);
        } else if (error.request) {
            alert('Error', error.request.responseJSON);
        } else {
            alert('Error', error.message);
        }
    })

  };

  React.useEffect(() => {
    console.log('sfgfgdfgdg');
    getTokenCosts();
  }, []);
    
  return(
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Token Info" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="10">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Channel
                </th>
                <th scope="col" className="border-0">
                  Rule Base Cost
                </th>
                <th scope="col" className="border-0">
                  Retry Cost
                </th>
                <th scope="col" className="border-0">
                  Free Retry Count
                </th>
                <th scope="col" className="border-0">
                  Refund Cancelled
                </th>
                <th scope="col" className="border-0">
                  Refund Skipped
                </th>
              </tr>
            </thead>
              <tbody>
              {
                Object.keys(tokenCostMap).map((channel) => {
                  return(
                    <tr>
                      <td>{tokenCostMap[channel]['channelType']}</td>
                      <td>{tokenCostMap[channel]['ruleCost']}</td>
                      <td>{tokenCostMap[channel]['retryCost']}</td>
                      <td>{tokenCostMap[channel]['freeRetryCount']}</td>
                      <td>%{tokenCostMap[channel]['reloadCancelledCostPercentage']}</td>
                      <td>%{tokenCostMap[channel]['reloadSkippedCostPercentage']}</td>
                    </tr>
                  );
                })
              }
              </tbody>
          </table>

          <br></br>
          <label><strong>* Rule Base Cost:</strong> Base fee for the channel </label><br></br>
          <label><strong>* Retry Cost:</strong> Retry fee for the each retry </label><br></br>
          <label><strong>* Free Retry Count:</strong> Free retry count </label><br></br>
          <label><strong>* Reload Cancelled:</strong> Percentage to be refunded in case of cancellation </label><br></br>
          <label><strong>* Reload Skipped:</strong> Percentage to be refunded in case of skip </label>

          <br></br><br></br>
          <h5>Example Calculations</h5>
          <label>1- Push rule with 4 retry  : 20 + (4-3)*5  = 25 tokens</label><br></br>
          <label>2- Sms rule with 2 retry (retries are free, beacuse free retry count is 2 for sms)     : 50 tokens</label><br></br>
          <label>3- Email rule with 2 retry (retries are free, beacuse free retry count is 3 for email)     : 10 tokens</label>
          <br></br><br></br>
          <label>Rule-1's cancellation refund amount : 25*0,8 = 20 (20 tokens will be refund and 5 tokens charged)</label>
          <label>Rule-2's skip refund amount : 50*0,5 = 25 (25 tokens will be refund and 25 tokens charged)</label>
        </Col>
      </Row>
    </Container>
  );
};

export default TokenInfo;
