import React,{ useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import axios from 'axios';
import ReminderList from "../components/reminder-dashboard/ReminderList";
import ConfigConstants from "../config/Config";
import SockJsClient from 'react-stomp';
import Cookies from 'js-cookie';

const ReminderDashboard = () => {
  const navigate = useNavigate();

  const [reminders, setReminders] = useState({
    "ACTIVE": {},
    "COMPLETED": {},
    "CANCELLED": {}
  });

  const getReminders = () =>  {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/reminder/retrieve?userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => {
      const response = res.data;

      if(response.success) {
        let reminderMap = {
          "ACTIVE": {},
          "COMPLETED": {},
          "CANCELLED": {}
        };

        let nowDate = new Date();
        const minuteForTimezone = nowDate.getTimezoneOffset();
  
        response.data.reminderGroups.forEach(reminderGroupData => {
          let createDateObj = new Date(reminderGroupData.createdDate);
          reminderMap[reminderGroupData.reminderGroupType][reminderGroupData.groupId] = [];
          reminderMap[reminderGroupData.reminderGroupType][reminderGroupData.groupId].push({
            rowType: 1,
            groupId: reminderGroupData.groupId,
            name: reminderGroupData.name,
            existProcessed: reminderGroupData.existProcessed,
            createdDate: createDateObj.addMinutes(-1*minuteForTimezone),
            cost: reminderGroupData.cost
          });
          reminderMap[reminderGroupData.reminderGroupType][reminderGroupData.groupId].push({
            rowType: 2,
            visible: false
          });
  
          reminderGroupData.reminders.forEach(reminder => {            
            let triggerTimeObj = new Date(reminder.triggerTime);
            
            reminderMap[reminderGroupData.reminderGroupType][reminderGroupData.groupId].push({
              rowType: 3,
              id: reminder.id,
              visible: false,
              type: reminder.channelType,
              triggerTime: triggerTimeObj.addMinutes(-1*minuteForTimezone),
              remainingRetryCount: reminder.remainingRetryCount,
              retryTime: reminder.retryTime,
              waitMinute: reminder.waitMinute,
              processed: reminder.processed,
              next: reminder.next,
              skipped: reminder.skipped,
              cancelled: reminder.cancelled
            });
          });
        });
  
        setReminders(reminderMap);

      } else {
        alert(response.message);
      }

    }) 
    .catch(error => { 
      if (error.response) {
          alert('Error', error.response.data);
      } else if (error.request) {
          alert('Error', error.request.responseJSON);
      } else {
          alert('Error', error.message);
      }
  })
  }

  Date.prototype.addMinutes = function(m) {
    this.setTime(this.getTime() + (m*60*1000));
    return this;
  }

  const SOCKET_URL = ConfigConstants.backendHost + '/ws-endpoint';

  let onConnected = () => {
    console.log("Web Socket Connected !!!")
  }

  let onMessageReceived = (msg) => {
    console.log("Reminder update trigger ...");
    if(Cookies.get('userId') === msg.userId) {
      getReminders();
      window.location.reload();
    }
  }

  React.useEffect(() => {
    if(Cookies.get("userId") && Cookies.get("jwtToken"))
      getReminders();
    else
      navigate("/login");
  }, [])

  return(
    <div>
      <SockJsClient
        url={SOCKET_URL}
        topics={['/topic/reminderUpdate']}
        onConnect={onConnected}
        onDisconnect={console.log("Disconnected!")}
        onMessage={msg => onMessageReceived(msg)}
        debug={false}
      />

      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Reminder Dashboard" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Active Reminders</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Created Time
                      </th>
                      <th scope="col" className="border-0">
                        Cost
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                    {
                      Object.values(reminders["ACTIVE"]).map((reminderGroup, mapIndex) => {
                        return(
                          <ReminderList mapIndex={(mapIndex+1)} reminderList={reminderGroup} active={true}/>
                        );
                      })
                    }
                    </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Completed Reminders</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Created Time
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                    {
                      Object.values(reminders["COMPLETED"]).map((reminderGroup, mapIndex) => {
                        return(
                          <ReminderList mapIndex={(mapIndex+1)} reminderList={reminderGroup} active={false}/>
                        );
                      })
                    }
                    </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Cancelled Reminders</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Created Time
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                    {
                      Object.values(reminders["CANCELLED"]).map((reminderGroup, mapIndex) => {
                        return(
                          <ReminderList mapIndex={(mapIndex+1)} reminderList={reminderGroup} active={false}/>
                        );
                      })
                    }
                    </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    
  );
};

export default ReminderDashboard;
