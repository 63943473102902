import React,{ useState }  from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import ConfigConstants from '../../config/Config';
import Cookies from 'js-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

const UserInfo = ({ title }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    name: "",
    surname: "",
    password: "",
    phone: "",
    pushToken: "",
  });

  const [phoneNumber, setPhoneNumber] = useState()
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getUserDetil = () =>  {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/user/detail?userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => { 
      const response = res.data;

      if(response.success) {
        setFormData({
          username: response.data.username,
          email: response.data.emailAddress,
          name: response.data.name,
          surname: response.data.surname,
          phone: response.data.phoneNumber,
          pushToken: response.data.appToken,
          remaininToken: response.data.tokenCount
        });
        setPhoneNumber(response.data.phoneNumber);
      } else {
        alert(response.message);
      }

    }) 
    .catch(error => { 
      if (error.response) {
          alert('Error', error.response.data);
      } else if (error.request) {
          alert('Error', error.request.responseJSON);
      } else {
          alert('Error', error.message);
      }
  })
  }

  const saveNewUser = () => {
    formData['phone'] = phoneNumber;

    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.post(ConfigConstants.backendHost + '/user/save', formData, { headers: headers })
    .then(res => {
      const response = res.data;

      if(response.success) {
        if(response.data.phoneNumber)
          Cookies.set("phoneNumber", response.data.phoneNumber);      

        if(response.data.appToken)
          Cookies.set("appToken", response.data.appToken);  

          alert('User Updated Successfully');
      } else {
        alert(response.message);
      }
      
    }) 
    .catch(error => { 
      if (error.response) {
          alert('Error', error.response.data);
      } else if (error.request) {
          alert('Error', error.request.responseJSON);
      } else {
          alert('Error', error.message);
      }
  })
  }

  React.useEffect(() => {
    if(Cookies.get("userId") && Cookies.get("jwtToken"))
      getUserDetil();
    else
      navigate("/login");
 }, [])
  
  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
             <Row form>
                {/* User Name */}
                <Col md="3" className="form-group">
                  <label htmlFor="feUsername"><u>Username</u></label> <br></br>
                  &nbsp;<label htmlFor="feUsername">{formData['username']}</label>
                </Col>
                <Col md="3" className="form-group">
                  <label htmlFor="feUsername"><u>Remaining Token</u></label> <br></br>
                  &nbsp;<label htmlFor="feUsername">{formData['remaininToken']}</label>
                </Col>
                {/* Email */}
                <Col md="6" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    id="feEmail"
                    name="email"
                    onChange={handleInputChange}
                    autoComplete="email"
                    value={formData['email']}
                  />
                </Col>
              </Row>
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feName">First Name</label>
                  <FormInput
                    id="feName"
                    name="name"
                    onChange={handleInputChange}
                    value={formData['name']}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feSurname">Last Name</label>
                  <FormInput
                    id="feSurname"
                    name="surname"
                    onChange={handleInputChange}
                    value={formData['surname']}
                  />
                </Col>
              </Row>
              <Row form>
                {/* Phone Number */}
                <Col md="6" className="form-group">
                  <label htmlFor="fePhone">Phone Number</label>
                  {/*<FormInput
                    id="fePhone"
                    name="phone"
                    onChange={handleInputChange}
                    value={formData['phone']}
                    />*/}
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}/>
                </Col>
                {/* App Token 
                <Col md="6" className="form-group">
                  <label htmlFor="fePushToken">App Token</label>
                  <FormInput
                    id="fePushToken"
                    name="pushToken"
                    onChange={handleInputChange}
                    value={formData['pushToken']}
                  />
                </Col>*/}
              </Row>
              <Button theme="accent" onClick={ saveNewUser }>Save User</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

UserInfo.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserInfo.defaultProps = {
  title: "User Info"
};

export default UserInfo;
