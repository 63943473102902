
import React,{ useState }  from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import Datetime from 'react-datetime-rocketsourceco';
import ChannelRules from "./ChannelRules";
import ConfigConstants from "../../config/Config";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import "react-datepicker/dist/react-datepicker.css"
import "../../assets/datetime.css"

import {
  Card,
  CardHeader, 
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button,
  Tooltip
} from "shards-react";


const NewUserInfo = ({ title }) => {
  const navigate = useNavigate();
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [channelList, setChannelList] = useState([]);
  const [phoneNumberNotExist, setPhoneNumberNotExist] = useState(false);
  const [appTokenNotExist, setAppTokenNotExist] = useState(false);
  const [totalTokenCost, setTotalTokenCost] = useState(0);
  const [tokenCostMap, setTokenCostMap] = useState({});

  const [rpTooltipOpen, setRpTooltipOpen] = useState(false);
  const [wtTooltipOpen, setWtTooltipOpen] = useState(false);
  const [chTooltipOpen, setChTooltipOpen] = useState(false);

  const [channelTooltipMsg, setChannelTooltipMsg] = useState("* Phone Call channel will be added soon \n * Push Notification channel will be added soon ");

  const [channelRule, setChannelRule] = useState({
    channelType: "Email",
    retryCount: "",
    retryPeriod: "",
    waitMinute: ""
  });

  const [reminderRequest, setReminderRequest] = useState({
    userId: Cookies.get('userId'),
    message: "",
    triggerTime: "",
    reminderName: "",
    retyryRules: []
  });

  React.useEffect(() => {
    if(Cookies.get("userId") && Cookies.get("jwtToken")) {
      setPhoneNumberNotExist(Cookies.get('phoneNumber') === undefined ? true : false);
      setAppTokenNotExist(Cookies.get('appToken') === undefined ? true : false);

      if(Cookies.get('phoneNumber') === undefined)
        setChannelTooltipMsg("* Add phone number for Sms channel \n " + channelTooltipMsg);

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get('jwtToken')
      }

      axios.get(ConfigConstants.backendHost + '/reminder/getTokenCosts', { headers: headers })
        .then(res => {
          const response = res.data;

          if(!response.success) {
            alert(response.message);
          } else {
            setTokenCostMap(response.data.channelTokenCostMap);
          }
        }) 
        .catch(error => { 
          if (error.response) {
              alert('Error', error.response.data);
          } else if (error.request) {
              alert('Error', error.request.responseJSON);
          } else {
              alert('Error', error.message);
          }
      })
    }
    else
      navigate("/login");
 }, [])

  const validDay = function( current ){
    var yesterday = Datetime.moment().subtract( 1, 'day' );
    return current.isAfter( yesterday );
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReminderRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }; 

  const handleRuleInputChange = (event) => {
    const { name, value } = event.target;

    setChannelRule((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }; 

  const saveRule = () => {
    let ruleCost = 0;
    let channelTokens = tokenCostMap[channelRule.channelType];
    
    ruleCost = ruleCost + channelTokens.ruleCost;
    if(parseInt(channelRule.retryCount) > channelTokens.freeRetryCount)
      ruleCost = ruleCost + ((channelRule.retryCount - channelTokens.freeRetryCount) * channelTokens.retryCost);

    setTotalTokenCost(totalTokenCost + ruleCost);

    setChannelList([
      ...channelList,
      channelRule
    ]);
  }

  const createNewReminder = () => {
    if(channelList === null) {
      console.log("Channel rule list is empty !!!");
    } else { 
      try {
        reminderRequest["triggerTime"] = selectedDate.toDate().getTime();
      } catch (e) {
        reminderRequest["triggerTime"] = 0;
      }
      reminderRequest["retryRules"] = channelList;

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get('jwtToken'),
        'Content-Type': 'application/json'
      }

      axios.post(ConfigConstants.backendHost + '/reminder/save', reminderRequest, { headers: headers })
      .then(res => { 
        const response = res.data;
        if(response.success)
          navigate("/reminders-dashboard");
        else
          alert(response.message);
      }) 
      .catch(error => { 
        if (error.response) {
            alert('Error', error.response.data);
        } else if (error.request) {
            alert('Error', error.request.responseJSON);
        } else {
            alert('Error', error.message);
        }
      })
    }

  }
  
  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
            <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feReminderName">Reminder Name</label>
                  <FormInput
                    id="feUsefeReminderName"
                    name="reminderName"
                    placeholder="Name for reminder"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md="6" className="form-group"></Col>
                <Col md="2" className="form-group">
                  <label htmlFor="feTotalCost"><h6>Token Cost</h6></label>
                  <br></br>
                  <label htmlFor="feTotalCost">{totalTokenCost}</label>
                </Col>
              </Row>
             <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feMessage">Message</label>
                  <FormInput
                    id="feUsefeMessagername"
                    name="message"
                    placeholder="Message for reminder"
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feTrigger">Trigger Time</label>
                  <div><Datetime isValidDate={ validDay } selected={selectedDate} onChange={(date) => setSelectedDate(date)} value={ selectedDate } /></div>
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feReminderChannel">Reminder Channel</label>
                  <span
                    id="CHTooltip"
                    style={{
                      display: 'inline-block',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      textAlign: 'center',
                      lineHeight: '12px',
                      marginLeft: '5px',
                      fontSize: '10px',
                      cursor: 'pointer',
                      position: 'relative',
                      top: '-5px'
                    }}
                  >?</span>
                  <Tooltip
                    open={chTooltipOpen}
                    target="#CHTooltip"
                    toggle={() => setChTooltipOpen(!chTooltipOpen)}
                  >
                    {channelTooltipMsg}
                  </Tooltip>
                  <FormSelect id="feReminderChannel" name="channelType" onChange={handleRuleInputChange}>
                    <option value="Email">Email</option>
                    <option disabled={phoneNumberNotExist} value="Sms">Sms</option> 
                    <option disabled="true" value="Call">Phone Call</option>
                    <option disabled="true" value="Push">Push Notification</option>
                  </FormSelect>
                </Col>
                <Col md="2" className="form-group">
                  <label htmlFor="feRetryCount">Retry Count</label>
                  <FormInput
                    id="feRetryCount"
                    name="retryCount"
                    type="number"
                    placeholder="Retry count"
                    onChange={handleRuleInputChange}
                  />
                </Col>
                {
                  channelRule.retryCount > 1 &&
                  <Col md="2" className="form-group">
                    <label htmlFor="feRetryPeriod">Retry Period</label>
                    <span
                      id="RPTooltip"
                      style={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        textAlign: 'center',
                        lineHeight: '12px',
                        marginLeft: '5px',
                        fontSize: '10px',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-5px'
                      }}
                    >?</span>
                    <Tooltip
                      open={rpTooltipOpen}
                      target="#RPTooltip"
                      toggle={() => setRpTooltipOpen(!rpTooltipOpen)}
                    >
                      Delay between two retries
                    </Tooltip>
                    <FormInput
                      id="feRetryPeriod"
                      name="retryPeriod"
                      type="number"
                      placeholder="Period in minutes"
                      onChange={handleRuleInputChange}
                    />
                  </Col>
                }
                {
                  channelList.length > 0 &&
                  <Col md="4" className="form-group">
                    <label htmlFor="feWaitMinute">Wait Time</label>
                    <span
                      id="WTTooltip"
                      style={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        textAlign: 'center',
                        lineHeight: '12px',
                        marginLeft: '5px',
                        fontSize: '10px',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-5px'
                      }}
                    >?</span>
                    <Tooltip
                      open={wtTooltipOpen}
                      target="#WTTooltip"
                      toggle={() => setWtTooltipOpen(!wtTooltipOpen)}
                    >
                      Delay after previous channel rules ending
                    </Tooltip>
                    <FormInput
                      id="feWaitMinute"
                      name="waitMinute"
                      type="number"
                      placeholder="Delay in minutes after executing preivous rule"
                      onChange={handleRuleInputChange}
                    />
                  </Col>
                }
              </Row>
              <Row form>
                <Col md="2" className="form-group" />
                <Col md="2" className="form-group" />
                <Col md="2" className="form-group" />
                <Col md="2" className="form-group" />
                <Col md="2" className="form-group" />
                <Col md="2" className="form-group">
                  <Button onClick={ saveRule }>Save Rule</Button>
                </Col> 
              </Row>
              {
                channelList.length > 0 && 
                <ChannelRules channelList={channelList} />
              }  
              <Button theme="accent" onClick={ createNewReminder }>Create Reminder</Button>         
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

NewUserInfo.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewUserInfo.defaultProps = {
  title: "Create New Reminder"
};

export default NewUserInfo;
